import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroArea from "../components/hero-area";
import CalloutBar from "../components/callout-bar";
import Card from "../components/card";
import styled from "styled-components";
import FeaturedItems from "../components/featured-items";
import Reputation from "../components/reputation";
import PhotoGalleryComponent from "../components/photo-gallery-component";
import RecentBlogPosts from '../components/RecentBlogPosts';

import CTA from "../components/cta-bar";
import "react-photoswipe/lib/photoswipe.css";
import { useTextReplacement } from "../hooks/useTextReplacement";
import ReviewTilesComponent from "../components/ReviewTilesComponent";
import { useValueTrackPageSwap } from "../hooks/useValueTrackPageSwap";
import SocialReviewsFullwidth from "../components/social-reviews-fullwidth";

import { TestimonialSection } from "../components/testimonial_video";
import Testimonials11 from "../components/Testimonials11";
import FAQ from "../components/FAQ";
import HeroFullBG from "../components/HeroFullBG";
import WhyUsSection from "../components/WhyUsSection";
import ProcessSection from "../components/OurProcess";
import Certifications from "../components/Certifications";
import HeroRatingStars from "../components/hero-rating-stars";
import { ArrowRight, Star } from "lucide-react";
import Button from "../components/atoms/Button";

const GalleryHomePage = styled.div`
  padding: 2.5em 0;
  background: white;
  text-align: center;
  h2 {
    margin-bottom: 0.35em;
    font-size: calc(0.75vw + 1.2em);
  }
  p {
    max-width: 767px;
    color: #555;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }
  img:nth-child(15) {
    display: none;
  }
`;

const IndexPage = (props) => {
  let homePageNode = useValueTrackPageSwap(props);

  const { testimonials: testimonial_video_posts } = (homePageNode &&
    homePageNode.testimonialSection &&
    homePageNode.testimonialSection) || { testimonials: [] };

  const SidebarColumn = () => {
    const sideBarCards = homePageNode.sidebar.map((sidebar, index) => <Card key={index} sidebar={sidebar} />);

    return sideBarCards;
  };

  return (
    <Layout
      pageProps={props}
      footerLogoShowcase={homePageNode && homePageNode.footerLogoShowcase && homePageNode.footerLogoShowcase}
      isHomePage={true}
      headerTransparent={true}
      headerShadow={false}
    >
      <SEO
        title={homePageNode.metaTitle}
        description={homePageNode.metaDescription}
        keywords={homePageNode.metaKeywordsList ? homePageNode.metaKeywordsList : []}
      />
      {/* {homePageNode.heroAreaCarousel || homePageNode.heroArea ? (
        <HeroArea heroContent={homePageNode.heroAreaCarousel ? homePageNode.heroAreaCarousel : homePageNode.heroArea} showForm={true} />
      ) : null} */}

      <HeroFullBG
        heroContent={homePageNode.heroAreaCarousel ? homePageNode.heroAreaCarousel : homePageNode.heroArea}
        showForm={true}
      />

        <ReviewTilesComponent twoThirds={false} />  

      {homePageNode.featuredItems ? (
        <div className="bg-gradient-to-b from-white to-brand-50">
          <FeaturedItems data={homePageNode.featuredItems} />
        </div>
      ) : (
        ""
      )}
            <Certifications />
         <div className="bg-gradient-to-br from-brand-50 to-white shadow">
        <TestimonialSection testimonial_video_posts={testimonial_video_posts} />

        <section className="max-w-7xl mx-auto px-4 md:px-6 pb-20">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/4">
              <p className="font-display text-5xl font-bold text-center">4.9/5</p>
              <div className={`flex items-center    justify-center py-3`}>
                {new Array(5).fill("").map((item) => (
                  <Star fill={"#f28d24"} stroke={"0"} size={18} />
                ))}
              </div>
              <h2 className="text-xl md:text-2xl font-display text-neutral-800 mb-2 font-extrabold text-center max-w-7xl mx-auto text-balance ">
                Hundreds of <span className="text-brand-600">Happy Customers</span>
              </h2>
              <p className=" mx-auto leading-relaxed  text-neutral-600 text-center max-w-4xl mx-auto py-2 text-sm md:text-base">
                Real reviews from real customers - see what they have to say about their experience with us.
              </p>

              <div className="mt-4 mx-auto flex justify-center border-t pt-4 hidden md:block">
                <Button color="brandGradient" size="big" className="w-full md:w-auto">
                  Request a FREE Estimate Now <ArrowRight size={18} />
                </Button>
              </div>
            </div>
            <div className="w-full md:w-3/4 md:pl-8">
              <Testimonials11 />
            </div>
          </div>
          <div className="mt-4 mx-auto flex justify-center border-t pt-4  md:hidden">
                <Button color="brandGradient" size="big" className="w-full md:w-auto">
                  Request a FREE Estimate Now <ArrowRight size={18} />
                </Button>
              </div>
        </section>
      </div>
      <ProcessSection />
      <WhyUsSection /> 
      <div className="bg-gradient-to-b from-neutral-50 to-white">
        {homePageNode && homePageNode.photoGallery && homePageNode.photoGallery[0].photos ? (
          <PhotoGalleryComponent gallery={homePageNode.photoGallery} />
        ) : null}
      </div>

      <SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
      <section>
        <div className="container">
          <div className="row">
            <div className={homePageNode.sidebar ? "col-12 is-service-page" : "col-12 is-service-page"}>
              {homePageNode && homePageNode.featuredContent && homePageNode.featuredContent.featuredContent ? (
                <div
                  className="prose md:prose-lg lg:prose-xl"
                  dangerouslySetInnerHTML={{
                    __html: homePageNode.featuredContent.featuredContent,
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="bg-gradient-to-b from-neutral-100 to-white">
      <div className="max-w-7xl mx-auto px-4 md:px-6 py-12 md:py-20">
          <RecentBlogPosts/>
          </div>
          </div>
      <div className="bg-neutral-50 py-12">
        <div className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
          <div
            className={`${homePageNode.sidebar ? "md:w-2/3 md:pr-4" : "w-full"} prose md:prose-lg  `}
            dangerouslySetInnerHTML={{ __html: homePageNode.body.body }}
          />

          {homePageNode.sidebar ? (
            <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
              <SidebarColumn />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <FAQ classnames={"-mb-12"} />
      <CTA
        title={"Start Your " + homePageNode.serviceTitle + " Project Today!"}
        customTitle={homePageNode.footerCallToActionHeading}
        customDesc={homePageNode.footerCallToActionDesc}
        bgimage={homePageNode.footerCallToActionImg}
        serviceHeroImage={homePageNode.heroImage}
        btn={"Get Started!"}
        props={props}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  fragment pageContent on ContentfulPageConnection {
    edges {
      node {
        featuredItems {
          id
          serviceTitle
          featuredLabel
          excerpt
          shortExcerpt
          slug
          heroImage {
            gatsbyImageData(width: 370, quality: 70, placeholder: NONE)
          }
        }
        id
        slug
        pageTitle
        metaTitle
        metaKeywordsList
        metaRobotsContent
        metaDescription
        featuredContent {
          featuredContent
        }
        body {
          body
        }
        footerLogoShowcase

        footerCallToActionHeading
        footerCallToActionDesc
        footerCallToActionImg {
          gatsbyImageData(width: 1400, placeholder: NONE)
        }
        photoGallery {
          id
          title
          description {
            description
          }
          photos {
            id
            title
            gatsbyImageData(width: 150, quality: 70, aspectRatio: 1.26153846154, placeholder: NONE)
            fullSize: gatsbyImageData(width: 1874, quality: 70, placeholder: NONE)
          }
        }
        heroArea {
          heroTitle
          description {
            description
          }
          primaryActionBtnLabel
          primaryActionBtnLink
          heroImage {
            gatsbyImageData(width: 1600, quality: 70, placeholder: BLURRED)
          }
        }
        heroAreaCarousel {
          heroTitle
          description {
            description
          }
          primaryActionBtnLabel
          primaryActionBtnLink
          heroImage {
            gatsbyImageData(width: 1600, quality: 70, placeholder: BLURRED)
            file {
              contentType
              url
            }
          }
        }
        sidebar {
          id
          title
          content {
            id
            content
          }
          cssClasses
        }
        testimonialSection {
          testimonials {
            id
            title
            subtitle
            projectReference {
              slug
            }
            video {
              id
              title
              file {
                url
                contentType
              }
            }
            thumbnail {
              gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
            }
          }
        }
      }
    }
  }

  {
    allContentfulPage(filter: { slug: { eq: "home" } }) {
      ...pageContent
    }
    campaignPages: allContentfulPage(filter: { slug: { glob: "home-*" } }, sort: { order: ASC, fields: createdAt }) {
      ...pageContent
    }
  }
`;
